import React, { useState, useRef } from "react"
import "./App.css"
import CustomButton from "./CustomButton"
import logo from "./appLogo.png"
import iphoneScreen from "./iphoneScreen.png"
import AppStoreComp from "./AppStoreComp"
import downloadAppStore from "./downloadAppStore.png"
import playStoreIcon from "./playStoreIcon.png"
import androidScreenshot from "./androidScreenshot.png"

function App() {
  const [clickID, setClickID] = useState(1)
  const desRef = useRef(null)
  const homeRef = useRef(null)

  return (
    <div className="App">
      <div className="Header">
        <CustomButton
          onClick={() => {
            setClickID(1)
            window.scrollTo(0, homeRef.current.offsetTop)
          }}
          clicked={clickID === 1}
          text="Home"
        />
        <CustomButton
          onClick={() => {
            setClickID(2)
            window.scrollTo(0, desRef.current.offsetTop)
          }}
          clicked={clickID === 2}
          text="Why use YoodyChat?"
        />
      </div>

      <div ref={homeRef} className="content">
        <img className="appLogo" alt="app logo" src={logo} />
        <h2 style={{ color: "#4d4d4d", marginTop: 20, marginBottom: 0 }}>
          YoodyChat
        </h2>
        <p style={{ color: "#808080", marginTop: 10 }}>
          a new way to communicate
        </p>
        <div className="appStoreCon">
          <AppStoreComp
            screenImg={iphoneScreen}
            appstoreIcon={downloadAppStore}
            appstoreLink={"https://apps.apple.com/us/app/yoodychat/id1612201064"}
          />
          <AppStoreComp
            screenImg={androidScreenshot}
            appstoreIcon={playStoreIcon}
            appstoreLink={"https://play.google.com/store/apps/details?id=com.yoodychat.chat"}
          />
        </div>
        <div ref={desRef}></div>
        <h3
          style={{
            color: "#4da6ff",
            marginTop: 40,
            alignSelf: "flex-start",
            marginLeft: 24
          }}
        >
          Why use YoodyChat?
        </h3>
        <div
          style={{
            alignSelf: "flex-start",
            marginLeft: 24,
            marginRight: 24
          }}
        >
          <p style={{ marginTop: 0 }}>
            Why do you need another messaging app? How many many messaging apps
            do we need? There are so many great apps to help you stay in touch
            with friends, family, and colleagues. They make it so easy, just
            load the app and it automatically puts you in touch with everyone in
            your address book. But what about messaging with people who are not
            your friends?
          </p>
          <p>
            Imagine you go to drop off your watch to get the battery replaced.
            The guy says sure you can pick it up later today, just give me your
            phone number and I’ll message you when it’s ready. Hmmmm. But do I
            really want to hand over my phone number?
          </p>
          <p>
            Or maybe you repair watches. Do you want to give your phone number
            to every customer just so you can tell them when their watch repair
            is done?
          </p>
          <p>
            Our phone numbers and email addresses are a great way to identify
            us, but once someone else has them they can contact us any time they
            wish. Life is full of situations where we need to establish
            communications for a short time and once our business is concluded
            that contact is no longer needed.
          </p>
          <p>
            YoodyChat is created for messaging all the other people. YoodyChat
            let’s you communicate Until Done (UD… “Yoody” <span role="img" aria-label="smiley">😃</span>).
          </p>

          <h3 style={{ color: "#4da6ff" }}>It gets better</h3>
          <p>
            If you have a business then YoodyChat has even more for you. Create
            your business chat with YoodyChat and you can add your employees.
            When your customer has a question or makes a request any employee
            available can take up the request.
          </p>
        </div>
      </div>
    </div>
  )
}

export default App
