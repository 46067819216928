import React from "react"
import "./App.css"

export default function AppStoreComp({ screenImg, appstoreIcon, appstoreLink }) {
  return (
    <a href={appstoreLink}>
    <div className="appStoreCompCon">
      <img
        style={{ width: 170, height: 340, backgroundImage: "white" }}
        alt="screen shot"
        src={screenImg}
      />
      <div className="appDes">
        <img
          style={{
            width: 120,
            height: 40,
            marginTop: 10
          }}
          alt="app store icon"
          src={appstoreIcon}
        />
      </div>
    </div>
    </a>
  )
}
