import React from "react"
import "./App.css"

export default function CustomButton({ text, clicked = false, onClick }) {
  return (
    <div
      onClick={onClick}
      style={{
        // backgroundColor: "white",
        borderBottomColor: clicked ? "#4da6ff" : "red",
        borderBottomStyle: "solid",
        borderBottomWidth: clicked ? 4 : 0,
        paddingLeft: 20,
        paddingRight: 20
      }}
    >
      <p className="text">{text}</p>
    </div>
  )
}
